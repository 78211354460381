"use client"

import { Button } from "../ui/button"
import { signOutAction } from "@/actions/public/signout"

export function SignOutButton({ className }: { className?: string }) {
    return (
        <form
            action={async () => {
                await signOutAction()
            }}
        >
            <Button type="submit" className={className}>
                Sign out
            </Button>
        </form>
    )
}
